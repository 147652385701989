import React                     from 'react';
import { graphql }               from 'gatsby';
import Img                       from 'gatsby-image';
import { Flex, Box }             from '@chakra-ui/react';
import { GiDiamondRing, GiShop } from 'react-icons/gi';
import Breakpoint                from 'react-socks';
import { Button, Center }        from '@chakra-ui/react';

import Heading                   from '@interness/web-core/src/components/text/Heading/Heading';
import Spacer                    from '@interness/web-core/src/components/structure/Spacer/Spacer';
import Wrapper                   from '@interness/web-core/src/components/structure/Wrapper/Wrapper';
import Separator                 from '@interness/web-core/src/components/structure/Separator/Separator';
import SEO                       from '@interness/web-core/src/components/modules/SEO/SEO';
import BrandsDisplay             from '@interness/theme-sonora/src/components/Display/BrandsDisplay';
import LiveAnnouncements
                                 from '@interness/web-core/src/components/structure/LiveAnnouncements/LiveAnnouncements';
import MoreExpander              from '@interness/web-core/src/components/structure/MoreExpander/MoreExpander';
import RandomBrandHeaderImages
                                 from '@interness/brands-addon/src/components/media/RandomBrandHeaderImages/RandomBrandHeaderImages';
import RandomSquareGalleryImages from '@interness/brands-addon/src/components/media/RandomSquareGalleryImages';
import FramedImage               from '@interness/theme-sonora/src/components/FramedImage';
import CallToAction              from '@interness/theme-sonora/src/components/CallToAction/CallToAction';
import Mounted                   from '@interness/web-core/src/components/structure/Mounted/Mounted';
import Link                      from '@interness/web-core/src/components/elements/Link/Link';


function IndexPage({ data }) {
  return (
    <>
      <SEO title={'Startseite'}/>
      <Mounted>
        <Breakpoint small down>
          <RandomSquareGalleryImages/>
        </Breakpoint>
        <Breakpoint medium up>
          <RandomBrandHeaderImages/>
        </Breakpoint>
      </Mounted>
      <Wrapper>
        <Spacer height={20}/>
        <Center>
          <Button sx={{ textDecoration: 'none' }} colorScheme="brand" as={Link} mx="5px" external
                  to="https://trauringstudio-grimm.de">Trauringstudio</Button>
          <Button variant="outline" sx={{ textDecoration: 'none' }} colorScheme="brand" as={Link} mx="5px"
                  to="produkte">Aktuelle Produkte</Button>
        </Center>
        <Spacer/>
        <LiveAnnouncements/>
        <section>
          <Heading subtitle={'Herzlich Willkommen'} icon={<GiDiamondRing/>}>Juwelier Grimm</Heading>
          <Flex flexWrap="wrap">
            <Box w={['100%', '100%', '50%', '50%']} p={[4, 4, 4, 12]} paddingRight={0}>
              <FramedImage>
                <Img fluid={data.laden.images[0].file.localFile.childImageSharp.fluid}/>
              </FramedImage>
            </Box>
            <Box w={['100%', '100%', '50%', '50%']} p={[4, 4, 4, 12]}>
              <p>Willkommen bei Juwelier Grimm, Ihrem vertrauensvollen Partner für feine Uhren und edlen Schmuck im
                Herzen von Siegen Weidenau. Mit tief verwurzelter Familien-Tradition und einer Geschichte, die sich über
                Generationen erstreckt, steht Juwelier Grimm für herausragende Qualität, unvergleichliche Handwerkskunst
                und einen Service, der Kundenbedürfnisse in den Mittelpunkt stellt.</p>
              <p>Bei Juwelier Grimm verstehen wir die emotionale Bedeutung, die ein Schmuckstück für Sie haben kann.
                Deshalb legen wir großen Wert darauf, dass jedes Stück in unserer Kollektionen nicht nur ästhetisch
                ansprechend, sondern auch von bester Qualität ist. Unser geschultes Team in Siegen am Hauptmarkt steht
                bereit, um Sie durch unsere vielfältige Auswahl zu führen und das perfekte Stück zu finden, das Ihre
                Persönlichkeit und Ihren Stil widerspiegelt.</p>
              <p>Neben unserem fein ausgewählten Sortiment an Uhren und Schmuck bieten wir umfassende Dienstleistungen
                für Reparaturen und Service an. Unsere Uhrmachermeister und Goldschmiedemeister sind spezialisiert auf
                die Wartung und Reparatur Ihrer kostbaren Lieblinge, sodass sie Generationen überdauern können. Egal, ob
                es sich um eine routinemäßige Wartung oder eine komplexe Reparatur handelt, wir garantieren höchste
                Sorgfalt und Präzision.</p>
              <MoreExpander title="Noch ein paar Worte zu Juwelier Grimm:">
                <p>Unsere Meisterwerkstatt, mit Uhrmachermeister und externem Goldschmiedemeister, bringt die Kunst der
                  Feinuhrmacherei und Schmuckgestaltung auf ein neues Level. Wir bieten eine exklusive Auswahl an Uhren,
                  Schmuck, Trauringen, Eheringen und Antragsringen, die jeden Ihrer besonderen Momente unvergesslich
                  machen.</p>

                <p>Wir verstehen auch den Wert von Altgold und bieten faire Preise für den Altgoldankauf an. Unsere
                  Expertise und Tradition in der Branche versichern Ihnen einen transparenten und vertrauenswürdigen
                  Service.</p>
                <p>Wir laden Sie herzlich ein, bei Ihrem Besuch in unserem Geschäft Ihre Lieblingsstücke auszuwählen,
                  anzuprobieren und zu begutachten.</p>

                <p>Gerne widmen wir Ihnen unsere Aufmerksamkeit für Ihre individuellen Wünsche bei Uhren- Schmuck und
                  nehmen uns die Zeit für Sie, damit Sie Ihre ganz persönlichen Trauringe und Eheringe finden.</p>

                <p>Bis bald in Siegen, Ihre Familie Grimm</p>
              </MoreExpander>
            </Box>
          </Flex>
        </section>
        <Spacer/>
        <Separator/>
        <section>
          <Heading tag={'h2'} icon={<GiShop/>}>Aktuelle Kollektionen</Heading>
          <Spacer height={40}/>
          <BrandsDisplay/>
        </section>
        <Spacer/>
        <CallToAction/>
      </Wrapper>
    </>
  )
}

export default IndexPage;

export const query = graphql`
    query {
        laden: directusMediaCollection(name: {eq: "laden"}) {
            name
            images: media {
                file {
                    localFile {
                        name
                        childImageSharp {
                            fluid(maxWidth: 800, maxHeight: 800, cropFocus: CENTER) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                }
            }
        }
    }
`;
